<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 createinvic_tabs">
			<ul class="export_tabs d-flex justify-content-around">
				<li :class="{ active: isServiceFax }" @click="changeTabsOpt('service_code')"><span>Service Codes</span></li>
				<li :class="{ active: isAdvanceSettings }" @click="changeTabsOpt('advance_settings')">
					<span v-if="form.type == 'Ontario'">Advanced Settings</span>
				</li>
			</ul>
			<div class="d-flex justify-content-between flex-column tab_contain">
				<div class="fax_menu h-100 service-code-form" :class="{ 'servce-code-tbl-list': this.finalizedItems.length > 5 }" v-if="isServiceFax">
					<div class="srvc-table">
						<table class="table text-center mb-0">
							<thead>
								<tr>
									<th style="width:calc(var(--scale-ratio) * 160px);" v-if="form.type == 'Ontario'">Billing Code</th>
									<th style="width:calc(var(--scale-ratio) * 160px);" v-if="form.type != 'Ontario'">Code</th>
									<th style="width:calc(var(--scale-ratio) * 130px);">Quantity</th>
									<th style="width:calc(var(--scale-ratio) * 90px);" v-if="form.type == 'Ontario'">%</th>
									<th style="width:calc(var(--scale-ratio) * 144px);">Value</th>
									<th style="width:calc(var(--scale-ratio) * 350px);" v-if="form.type != 'Ontario'">Description</th>
									<th style="width:calc(var(--scale-ratio) * 160px);" v-if="form.type == 'Ontario'">Dx Code</th>
									<th style="width:calc(var(--scale-ratio) * 150px);border-right: 0;">Date</th>
									<th style="width: calc(var(--scale-ratio) * 170px);border: 0;background: transparent;"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in finalizedItems" :key="index">
									<td>{{ item.service_code }}</td>
									<td>{{ item.number_of_service }}</td>
									<td v-if="form.type == 'Ontario'">{{ item.discount }}</td>
									<td>{{ item.fee_submitted_format }}</td>
									<td>{{ item.dx_code }}</td>
									<td>{{ item.service_date }}</td>
									<td class="edit_delet_link">
										<span class="popup_link" @click="editEle(index, item)">Edit</span>
										<span class="popup_link" @click="deleteEle(index)">Delete</span>
									</td>
								</tr>
								<tr v-if="isShowBillingFrmEle">
									<td class="position-relative seach_input_bar input_box vh-drop-list actvbr-none billingCode-serch-list" :class="{ 'drop-top-post-list': this.finalizedItems.length > 5 }">
										<input type="text" class="form-control" v-model="itemForm.service_code" @keyup="searchBillingCode" @focus="searchBillingCode" @blur="checkduplicate">
										<div class="search_drop_box new_big_drop billing-code-tbl-drpdown" v-if="billingCodeList.length > 0" ref="billingCodeRef">
											<slot v-for="(item, index) in billingCodeList" :key="index">
												<div class="con_drop_line text-start" @click="selectBillingCode(item)">
													<div class="man_haeding d-flex align-items-center blgcode-list-dropList">
														<p class="mb-0">{{ item.code }} | {{ item.formatted_fee }} |</p>
														<span> {{ item.description }}</span>
													</div>
												</div>
											</slot>
										</div>
									</td>
									<td>
										<input type="number" class="form-control text-center shdw-inpt-box quantity_number" v-model="itemForm.number_of_service" @change="calculateGrnadTotal" style="width:calc(var(--scale-ratio) * 60px);margin: 0 auto;">
									</td>
									<td v-if="form.type == 'Ontario'">
										<input type="number" class="form-control shdw-inpt-box" v-model="itemForm.discount" step="0.01" min="0" max="100" @change="calculateGrnadTotal">
									</td>
									<td>{{ itemForm.fee_submitted_format }}</td>
									<td class="position-relative seach_input_bar input_box drp-stl-text-adjst actvbr-none dxcode-serch-list" :class="{ 'drop-top-post-list': this.finalizedItems.length > 5 }" v-if="form.type == 'Ontario'">
										<input type="text" class="form-control" v-model="itemForm.dx_code" @keyup="searchDxCode" @focus="searchDxCode" @blur="addDxDescription">
										<div class="search_drop_box new_big_drop vh-drop-list-r" v-if="dxCodeList.length > 0" ref="dxCodeRef">
											<slot v-for="(item, index) in dxCodeList" :key="index">
												<div class="con_drop_line" @click="selecDxCode(item)">
													<div class="man_haeding mb-0 dxCode-list-drop d-flex align-items-center">
														<p class="code-big-f mb-0">{{ item.code }} |</p> <span> {{ item.description }}</span>
													</div>
												</div>
											</slot>
										</div>
									</td>
									<td class="position-relative seach_input_bar input_box vh-drop-list actvbr-none description-code-table" :class="{ 'drop-top-post-list': this.finalizedItems.length > 5 }" v-if="form.type != 'Ontario'">
										<input type="text" class="form-control" v-model="itemForm.service_code_description" @keyup="searchBillingDesc" @focus="searchBillingDesc">
										<div class="search_drop_box new_big_drop" v-if="billingCodeDescList.length > 0" ref="billingPrivateDescRef">
											<slot v-for="(item, index) in billingCodeDescList" :key="index">
												<div class="con_drop_line d-flex justify-content-between align-items-center text-start" @click="selectBillingCodeDesc(item)">
													<div class="man_haeding d-flex align-items-center blgcode-list-dropList">
														<p class="mb-0">{{ item.code }} | {{ item.formatted_fee }} |</p>
														<span> {{ item.description }}</span>
													</div>
												</div>
											</slot>
										</div>
									</td>
									<td class="actvbr-none datepicker_bx">
										<div class="calendar dt-cldr-ads cldr-date-pick-tbl _datepic" style="width: calc(var(--scale-ratio) * 150px);margin: 0 auto;">
											<ejs-datepicker class="e-field form-control" @change="handleDate('service_date')" @blur="handleDate('service_date')" ref="service_date" v-model="itemForm.service_date" :showClearButton="false" format="yyyy-MM-dd" @keyup="this.keyupdate('service_date')" id="service_date"></ejs-datepicker>
										</div>
									</td>
									<td class="text-center" style="padding: calc(var(--scale-ratio) * 20px) calc(var(--scale-ratio) * 16px);">
										<button type="button" class="comman_btn serv-addbtn" @click="addNewItemToFinalized">Add</button>
									</td>
								</tr>
								<tr>
									<td class="w-auto border-0" colspan="7">
										<div class="plus_sign_row ontario-plus-sign" @click="createNewItem" v-if="form.type == 'Ontario'">+</div>
										<div class="plus_sign_row private-plus-sign" @click="createNewItem" v-else>+</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="fax_menu" v-if="isAdvanceSettings">
					<div class="ad-from-sect creatinv-main">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>HEALTH NUMBER</label>
									<input type="text" class="form-control" v-model="form.health_insurance_no">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>VERSION CODE</label>
									<input type="text" class="form-control" v-model="form.health_card_ver">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from sm-prv-code">
									<label>PROVINCE CODE</label>
									<CustomDropDown :options="stateData" :initialValue="form.province_code_text" v-model="form.province_code" @item-selected="handleItemSelected" fieldName="province_code"></CustomDropDown>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>CLAIM PAYEE</label>
									<input type="text" class="form-control" v-model="form.claim_payee">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>LAST NAME</label>
									<input type="text" class="form-control" v-model="form.rmb_patient_last_name">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from">
									<label>FIRST NAME</label>
									<input type="text" class="form-control" v-model="form.rmb_patient_first_name">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from birth-date-ipt">
									<label>DATE OF BIRTH</label>
									<div class="calendar dt-cldr-ads _datepic date-icon-remove">
										<ejs-datepicker class="e-field form-control" @blur="handleDate('dob')" @change="handleDate('dob')" v-model="form.dob" ref="dob" :showClearButton="false" format="yyyy-MM-dd" @keyup="this.keyupdate('dob')" id="dob"></ejs-datepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div class="adv-stg-from gender-select-dropdown">
									<label>GENDER</label>
									<CustomDropDown :options="gender_option" :initialValue="this.form.gender_text" v-model="form.rmb_patient_sex" @item-selected="handleItemSelected" fieldName="rmb_patient_sex"></CustomDropDown>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from overhd-drop-tect drp-width-big">
									<label>CLAIM TYPE</label>
									<CustomDropDown :options="claim_type_option" :initialValue="this.form.claim_type_text" v-model="form.claim_type" @item-selected="handleItemSelected" fieldName="claim_type"></CustomDropDown>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>REFERRAL ID</label>
									<input type="text" class="form-control" v-model="form.referral_hcp_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>REGISTRATION NUMBER (FOR RMB)</label>
									<input type="text" class="form-control" v-model="form.rmb_registration_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>VISIT LOCATION</label>
									<div class="visit-location_selct w-100 visit_location_ele position-relative">
										<input type="text" class="form-control cursor-auto" v-model="form.visit_location_text" @keyup="searchVisitLocation" @focus="searchVisitLocation">
										<div class="search_drop_box new_big_drop billing-code-tbl-drpdown" v-if="filteredVisitLocations.length > 0" ref="visitLocationRef">
											<slot v-for="(item, index) in filteredVisitLocations" :key="index">
												<div class="con_drop_line text-start" @click="selectVisitlocation(item)">
													<div class="man_haeding">
														<p class="mb-0">{{ item.title }}</p>
													</div>
												</div>
											</slot>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>SERVICE LOCATION INDICATOR</label>
									<!-- Do not remove location_indicator_ele -->
									<div class="location_indicator_select w-100 location_indicator_ele position-relative">
										<input type="text" class="form-control cursor-auto" v-model="form.service_location_text" @keyup="searchServiceLocation" @focus="searchServiceLocation">
										<div class="search_drop_box new_big_drop billing-code-tbl-drpdown" v-if="filteredServiceLocations.length > 0" ref="serviceLocationRef">
											<slot v-for="(item, index) in filteredServiceLocations" :key="index">
												<div class="con_drop_line text-start" @click="selectServicelocation(item)">
													<div class="man_haeding">
														<p class="mb-0">{{ item.title }}</p>
													</div>
												</div>
											</slot>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from admission-date-field">
									<label>ADMISSION DATE</label>
									<div class="calendar dt-cldr-ads _datepic date-icon-remove">
										<ejs-datepicker class="e-field form-control" @change="handleDate('inpatient_admission_date')" @blur="handleDate('inpatient_admission_date')" v-model="form.inpatient_admission_date" :showClearButton="false" format="yyyy-MM-dd" @keyup="this.keyupdate('inpatient_admission_date')" :strictMode="true" id="inpatient_admission_date" ref="inpatient_admission_date"></ejs-datepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>REFERRING LAB LICENSE NUMBER</label>
									<input type="text" class="form-control" v-model="form.referring_lab_license_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>GROUP NUMBER OVERRIDE</label>
									<input type="text" class="form-control" v-model="form.group_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>SPECIALTY OVERRIDE</label>
									<div class="specialty-override-select w-100 position-relative speciality_ele">
										<input type="text" class="form-control cursor-auto" v-model="form.specialty_code_desc" @keyup="searchSpecialityCode" @focus="searchSpecialityCode">
										<div class="search_drop_box new_big_drop billing-code-tbl-drpdown" v-if="filteredSpecialityCode.length > 0" ref="specialityRef">
											<slot v-for="(item, index) in filteredSpecialityCode" :key="index">
												<div class="con_drop_line text-start" @click="selectSpecialityCode(item)">
													<div class="man_haeding">
														<p class="mb-0">{{ item.title }}</p>
													</div>
												</div>
											</slot>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>MASTER NUMBER</label>
									<input type="text" class="form-control" v-model="form.master_number">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4">
								<div class="adv-stg-from">
									<label>MANUAL REVIEW</label>
									<div class="w-100p">
										<label class="switch d-block cursor-default">
											<input type="checkbox" id="darkModeToggle" v-model="form.manual_review">
											<span class="sw-slider"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="ttl-sect">
						<div class="d-flex justify-content-end">
							<p class="t-ttl-h">TOTAL |</p>
							<p class="ttl-price">{{ this.grandTotal }}</p>
						</div>
					</div>
					<div class="btn_part text-end">
						<button class="comman_brdr_btn big_btn mx40" @click="cancelBtn">Cancel</button>
						<button class="comman_btn big_btn mx40 me-0" @click="save('Saved')">Save <img src="images/loader.gif" v-if="saveBtnLoader" style="width: calc(var(--scale-ratio) * 18px);" /> </button>
						<button class="comman_btn big_btn mx40 me-0" @click="save('Submit')">{{ this.btnlbl }} <img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" /> </button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 invoice-left-bar">
			<div>
				<div class="pt-dashbrd">
					<p class="lbl-iput"><span>INVOICE TYPE</span></p>
					<div class="heading_selact_drodwn inv-type-drop-li">
						<CustomDropDown :options="invoice_type_opt" :initialValue="this.invoice_type_text" @item-selected="handleItemSelected" fieldName="type" :isCreateInvoice="isTypeDropDownDisabled">
						</CustomDropDown>
						<ValidationErrorMessageList :errors="v$.form.type.$errors" />
					</div>
				</div>
				<p class="lbl-iput"><span>DEMOGRAPHIC</span></p>
				<div class="position-relative">
					<div class="search">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" @keyup="patientSearch" class="form-control w-100" placeholder="Search Demographic" v-model="patient_search" ref="globalSearch">
						<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
					</div>
					<div class="search_drop_box src_new_box billing-demogrphc-search" v-if="patientList.length > 0">
						<slot v-for="(item, index) in patientList" :key="index">
							<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
								<div class="row align-items-center">
									<div class="col-md-12">
										<div class="man_haeding"> {{ item.last_name }}, {{ item.first_name }}
											<span>({{ item.gender }})</span>
										</div>
										<ul>
											<li>
												DOB: {{ item.dob }}
											</li>
											<li>
												HIN: {{ item.health_insurance_no }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</slot>
					</div>
				</div>
			</div>
			<div class="mt-20 pt-dashbrd">
				<p class="ptnt-dtls overflow-hidden" style="width: calc(var(--scale-ratio) * 380px);text-overflow: ellipsis;"><span>Health # |</span>{{ this.patient_detail.health_insurance_no }}
					{{ this.patient_detail.health_card_ver }}
					<slot>{{ patient_detail.health_insurance_type ? `(${patient_detail.health_insurance_type})` : '' }}</slot>
				</p>
				<div class="d-flex">
					<div class="lft-de-sct">
						<p class="ptnt-dtls"><span>Date of Birth |</span>{{ this.patient_detail.dob }}</p>
					</div>
					<div class="rgt-de-sct">
						<p class="ptnt-dtls ms-0"><span class="w-auto">Age |</span>{{ $filters.patientCalculatedAge(this.patient_detail.dob, true) }}</p>
					</div>
				</div>
			</div>
			<div>
				<p class="lbl-iput"><span>PROVIDER</span></p>
				<div class="selact_dropdown bigs-slct-dr h-auto d-inline-block w-100 prvdr-drp-list">
					<selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="true" :isUnselectAll="isUnselectAll" divMainClass="" :isMultiSelection="isMultiSelection" />
					<ValidationErrorMessageList :errors="v$.form.provider_id.$errors" />
				</div>
			</div>
			<div class="mt-10 pt-dashbrd">
				<div class="right-provider-id">
					<div class="left-pr-sect">
						<p class="ptnt-dtls"><span>Provider ID |</span>{{ trimToSixDigits(this.selected_provider_detail.cpsid) }}</p>
					</div>
					<div class="rigt-pr-sect">
						<p class="ptnt-dtls"><span>Specialty |</span>{{ this.selected_provider_detail.specialty_code }}</p>
					</div>
				</div>
				<div class="left-provider-lctn d-flex">
					<div class="left-pr-sect">
						<p class="ptnt-dtls"><span>Visit Location |</span>
							{{ displayCode(this.form.visit_location_text) }}</p>
					</div>
					<div class="rigt-pr-sect">
						<p class="ptnt-dtls"><span>Group # |</span>{{ this.selected_provider_detail.group_billing_no }}</p>
					</div>
				</div>
			</div>
			<div class="mt-20">
				<p class="smallhead">PREVIOUS INVOICES</p>
				<div class="table-responsive previ-innce">
					<table class="table text-center mb-0">
						<thead>
							<tr>
								<th style="width: calc(var(--scale-ratio) * 105px);">Date</th>
								<th>Codes</th>
								<th>Dx</th>
								<th style="width: calc(var(--scale-ratio) * 100px);">Type</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in this.previousInvoices" :key="index">
								<td class="white-space-pre">{{ item.service_date }}</td>
								<td>{{ item.service_codes }}</td>
								<td>{{ item.dx_codes }}</td>
								<td>{{ item.type }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue'
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import selectCheckBox from "../base/selectCheckBox.vue";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
import moment from "moment";

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	validations() {
		return this.$store.state.billing.validationRules;
	},
	data() {
		return {
			isServiceFax: true,
			isAdvanceSettings: false,
			invoice_type_opt: [
				{ value: "Ontario", title: "ONTARIO" },
				{ value: "Private", title: "PRIVATE" },
			],
			invoice_type_text: "ONTARIO",
			patientList: [],
			patient_search: "",
			provider_list: [],
			tagProviderLabel: "Select Provider",
			isUnselectAll: false,
			isMultiSelection: false,
			finalizedItems: [],
			previousInvoices: [],
			patient_detail: [],
			selected_provider_id: null,
			isShowBillingFrmEle: true,
			loader: false,
			saveBtnLoader: false,
			billingCodeList: [],
			dxCodeList: [],
			preBillingCodeList: [],
			preDxCodeList: [],
			stateData: [
				{ value: "AB", title: "AB - Alberta" },
				{ value: "BC", title: "BC - British Columbia" },
				{ value: "MB", title: "MB - Manitoba" },
				{ value: "NB", title: "NB - New Brunswick" },
				{ value: "NL", title: "NL - Newfoundland" },
				{ value: "NS", title: "NS - Nova Scotia" },
				{ value: "ON", title: "ON - Ontario" },
				{ value: "PE", title: "PE - Prince Edward Island" },
				{ value: "QC", title: "QC - Quebec" },
				{ value: "SK", title: "SK - Saskatchewan" },
				{ value: "NT", title: "NT - Northwest Territories" },
				{ value: "NU", title: "NU - Nunavut" },
				{ value: "YT", title: "YT - Yukon" },
				{ value: "OT", title: "OT - Other" },
			],
			gender_option: [
				{ value: "M", title: "Male" },
				{ value: "F", title: "Female" },
				{ value: "T", title: "Transgendered" },
				{ value: "O", title: "Other" },
				{ value: "U", title: "Undefined" }
			],
			claim_type_option: [
				{ value: "HCP", title: "HCP - Health Claims Program | <span class='fw-300 fs-14'> Ontario </span>" },
				{ value: "WCB", title: "WCB - Workplace Compensation Board" },
				{ value: "RMB", title: "RMB - Reciprocal Medical Billing | <span class='fw-300 fs-14'>Other Provinces besides Quebec</span>" },
			],
			// claim_payee_option:[
			// 	{ value: "P", title: "Provider" },
			// 	{ value: "S", title: "Patient" },
			// ],
			serviceLocationData:[
				{ "value": "None", "title": "None" },
				{ "value": "HDS", "title": "HDS - Hospital Day Surgery" },
				{ "value": "HED", "title": "HED - Hospital Emergency Department" },
				{ "value": "HIP", "title": "HIP - Hospital In-Patient" },
				{ "value": "HOP", "title": "HOP - Hospital Out-Patient" },
				{ "value": "HRP", "title": "HRP - Hospital Referred Patient" },
				{ "value": "IHF", "title": "IHF - Independent Health Facility" },
				{ "value": "OFF Physician", "title": "OFF - Office of Community Physician" },
				{ "value": "OTN", "title": "OTN - Ontario Telemedicine Network" },
				{ "value": "PDF", "title": "PDF - Private Diagnostic Facility" },
				{ "value": "RTF", "title": "RTF - Rehabilitation Treatment Facility" }
			],

			grandTotal: "$0.00",
			billingCodeDescList: [],
			btnlbl: 'Save & Submit',
			selected_provider_detail: [],
			select_box_key: 0,
			displayDiv: false,
			isEditInvoice: false,
			onEditRemoveIds: [],
			filteredVisitLocations:[],
			visitLocationData:[],
			filteredServiceLocations: [],
			filteredSpecialityCode:[],
			specialityCodes:[],
		}
	},
	components: {
		sidebar,
		CustomDropDown,
		selectCheckBox,
		'ejs-datepicker': DatePickerComponent,
		ValidationErrorMessageList,
	},
	methods: {
		displayCode(text){
			if(text){
				const code = text.split(/[-–]/)[0].trim();
				return code;
			}
		},
		trimToSixDigits(number) {
			if (number) {
				let numberString = number.toString();
				let trimmedString = numberString.slice(0, 6);
				let trimmedNumber = parseInt(trimmedString, 10);
				return trimmedNumber;
			} else {
				return ""
			}
		},
		keyupdate(obj) {
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		changeTabsOpt(type) {
			this.isServiceFax = false
			this.isAdvanceSettings = false
			if (type == 'service_code') {
				this.isServiceFax = true
			} else if (type == 'advance_settings') {
				this.isAdvanceSettings = true
			}
		},
		patientSearch() {
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if (this.patient_search.length == 0) {
				this.patientList = [];
			}
		},
		handleItemSelected(values) {
			if (values.fieldName == "claim_type") {
				let claimTypeVal = values.item.title;
				this.form.claim_type_text = claimTypeVal.split('|')[0].trim();
				this.form.claim_type = claimTypeVal.split(/[-–]/)[0].trim();
			} else if (values.fieldName == "province_code") {
				this.form.province_code_text = values.item.title
				this.form[values.fieldName] = values.item.value
			}
			else {
				if (values.fieldName == 'type') {
					this.resetForm()
					this.billingCodeList = []
					this.dxCodeList = []
					if (values.item.value != "Ontario") {
						this.changeTabsOpt('service_code')
					}
					/* Assign provier_id & patient_id on form */
					this.initializeForm();
				}
				this.form[values.fieldName] = values.item.value;
				if(this.patient_detail.id){
					this.form.patient_id = this.patient_detail.id;
					this.form.health_insurance_no = this.patient_detail.health_insurance_no
					this.form.health_card_ver = this.patient_detail.health_card_ver
					this.form.rmb_patient_first_name = this.patient_detail.first_name
					this.form.rmb_patient_last_name = this.patient_detail.last_name
					this.form.dob = this.patient_detail.dob
					this.form.rmb_patient_sex = this.patient_detail.gender
					this.form.gender_text = (this.gender_option.find(item => item.value === this.patient_detail.gender) || {}).title || "Select Gender";
				}

				if(this.selected_provider_detail.id){
					this.form.provider_id = this.selected_provider_detail.id;
					if (this.selected_provider_detail.visit_location) {
						this.form.visit_location = this.selected_provider_detail.visit_location
						this.form.visit_location_text = this.selected_provider_detail.visit_location;

						this.form.service_location = this.selected_provider_detail.service_location
						this.form.service_location_text = this.selected_provider_detail.service_location;
						
						this.form.specialty_code_desc = this.selected_provider_detail.specialty_description+' ('+this.selected_provider_detail.specialty_code+')'
					}
				}
			}
		},
		async selectPatient(id, patient_name) {
			// this.$store.state.patient_document.form.patient_id = id
			this.patient_search = patient_name.toUpperCase();
			this.form['patient_id'] = id
			this.fetchPatientDetail(id);
			this.fetchPreviousInvoice()
			this.patientList = [];
			this.form.rmb_registration_number = '';
		},
		selected_provider(result) {
			let obj = result.filter(item => item.checked)
			let checkedItems = obj.map(item => item.name);
			if (checkedItems.length > 0) {
				this.tagProviderLabel = checkedItems[0]
				this.form['provider_id'] = obj[0].id
				this.selected_provider_detail = obj[0].extInfo
				if (this.selected_provider_detail.visit_location) {
					this.form.visit_location = this.selected_provider_detail.visit_location;
					this.form.visit_location_text = this.selected_provider_detail.visit_location;
					this.form.service_location = this.selected_provider_detail.service_location;
					this.form.service_location_text = this.selected_provider_detail.service_location;
					this.form.specialty_code_desc = this.selected_provider_detail.specialty_description+' ('+this.selected_provider_detail.specialty_code+')';
				} else {
					this.form.visit_location = null;
					this.form.visit_location_text = '';
					
				}
				if (this.selected_provider_detail.service_location) {
					this.form.service_location = this.selected_provider_detail.service_location
					// eslint-disable-next-line no-unused-vars
					const [code, description] = this.selected_provider_detail.service_location.split(/[-–]/).map(str => str.trim());
					this.form.service_location_text = code;
				} else {
					this.form.service_location = null
					this.form.service_location_text = '';
					this.form.specialty_code_desc = ''
				}
				
				this.form.specialty_code_desc = this.selected_provider_detail.specialty_description+' ('+this.selected_provider_detail.specialty_code+')';
			}
			// this.fetchPreviousInvoice()
		},
		async getProviderList() {
			try {
				const response = await axios.post("json_list/provider", { roleId: [2] });

				this.provider_list = response.data.data.map((item) => {
					return { id: item.id, name: item.full_name, checked: false, extInfo: item };
				});
				if (this.$route.query.provider_id) {
					this.provider_list = response.data.data.map((item) => {
						return {
							id: item.id,
							name: item.full_name,
							checked: item.id === parseInt(this.$route.query.provider_id, 10),
							extInfo: item
						};
					});
				}
			} catch (error) {
				console.error('Error fetching provider list:', error);
			}
		},
		save(status) {
			this.v$.$validate();
			const $this = this
			if (!this.v$.$error) {
				if(this.form.type == "Ontario"){
					if(this.form.health_insurance_no === null || this.form.health_card_ver === null || this.form.dob === null){
						this.$filters.moshaToast('Ontario Health Card Information is required', "error");
						return false;
					}
					const healthCardVer = this.form.health_card_ver;
					if (healthCardVer === null || healthCardVer.length !== 2 || !/^[a-zA-Z]+$/.test(healthCardVer)) {
						if (healthCardVer === null) {
							this.$filters.moshaToast("Health card ver. is required.", "error");
						} else if (healthCardVer.length < 2) {
							this.$filters.moshaToast("Please enter Health card ver. at least 2 characters.", "error");
						} else if (healthCardVer.length > 2) {
							this.$filters.moshaToast("Please enter Health card ver. max 2 characters.", "error");
						} else {
							this.$filters.moshaToast("Health card ver. must be alphabetic characters only.", "error");
						}
						return false;
					}
				}
				if(status === 'Saved'){
					this.form.status = status;
					$this.saveBtnLoader = true;
				} else if(status === 'Submit') {
					this.form.status = "Pending";
					$this.loader = true;
				}else{
					$this.loader = true;
				}
				this.form['items'] = this.finalizedItems;
				this.form.remove_ids = this.onEditRemoveIds;

				let url = ''
				let invoiceType = '';
				if (this.form.id) {
					if (this.form.type == "Ontario") {
						url = 'invoice/public/update'
						invoiceType = "Ontario"
					} else {
						url = 'invoice/private/update'
						invoiceType = "Private"
					}
				} else {
					if (this.form.type == "Ontario") {
						url = 'invoice/public/store'
						invoiceType = "Ontario"
					} else {
						url = 'invoice/private/store'
						invoiceType = "Private"
					}
				}

				axios.post(url, this.form)
					.then((response) => {
						if (response.status == 200) {
							if (status === "Saved") {
								$this.saveBtnLoader = false;
							} else {
								$this.loader = false;
							}
							this.onEditRemoveIds = [];
							this.$router.push(`/invoice/list?invoiceId=${response.data.data.id}&invoiceType=${invoiceType}`);
							this.$filters.moshaToast(response.data.message, "success");
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						$this.loader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
			} else {
				for (const [key] of Object.entries(this.v$.form)) {
					if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		addNewItemToFinalized() {
			if(this.itemForm.service_code){
				var duplicateFound = this.finalizedItems.some(item => item.service_code.toLowerCase() === this.itemForm.service_code.toLowerCase());
				if (duplicateFound) {
					this.$filters.moshaToast("You cannot add duplicate billing codes.", "error");
					return false;
				}
				var billmatchFound = (this.itemForm.service_code) ? this.preBillingCodeList.some(item => item.code.toLowerCase() === this.itemForm.service_code.toLowerCase()) : false;
			}
			if (!this.itemForm.service_code || !billmatchFound) {
				if (this.form.type == "Ontario") {
					this.$filters.moshaToast("Please select billing code", "error")
				} else {
					this.$filters.moshaToast("Please select code", "error")
				}
				return false
			}

			if (this.form.type == "Ontario") {
				var dxmatchFound = (this.itemForm.dx_code) ? this.preDxCodeList.some(item => item.code.toLowerCase() === this.itemForm.dx_code.toLowerCase()) : false;
				if (!this.itemForm.dx_code || !dxmatchFound) {
					this.$filters.moshaToast("Please select Dx code", "error")
					return false
				}
			}

			if (!this.itemForm.service_date) {
				this.$filters.moshaToast("Please select date", "error")
				return false
			}

			if (!this.itemForm.fee_submitted_format) {
				this.$filters.moshaToast("Please select billing code", "error")
				return false
			}

			this.isShowBillingFrmEle = false
			this.finalizedItems.push({ ...this.itemForm });
			this.calculateGrnadTotal()
			Object.assign(
				this.$store.state.billing.itemForm,
				this.$store.state.billing.defaultItemForm,
			);
		},
		fetchPatientDetail(patientId) {
			axios.post("patient/detail", { 'id': patientId })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
						this.form.health_insurance_no = this.patient_detail.health_insurance_no
						this.form.health_card_ver = this.patient_detail.health_card_ver
						this.form.rmb_patient_first_name = this.patient_detail.first_name
						this.form.rmb_patient_last_name = this.patient_detail.last_name
						this.form.dob = this.patient_detail.dob
						this.form.rmb_patient_sex = this.patient_detail.gender
						this.form.gender_text = (this.gender_option.find(item => item.value === this.patient_detail.gender) || {}).title || "Select Gender";
						if (this.$route.query) {
							var patient_name = this.patient_detail.last_name + ', ' + this.patient_detail.first_name;
							this.patient_search = patient_name.toUpperCase();
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		createNewItem() {
			Object.assign(
				this.$store.state.billing.itemForm,
				this.$store.state.billing.defaultItemForm,
			);
			this.isShowBillingFrmEle = true
			const lastDxCode = this.finalizedItems.length > 0 ? this.finalizedItems.slice(-1)[0].dx_code : '';
			const lastDxCodeDes = this.finalizedItems.length > 0 ? this.finalizedItems.slice(-1)[0].dx_code_description : '';
			this.itemForm.dx_code = lastDxCode;
			this.itemForm.dx_code_description = lastDxCodeDes;

			this.searchDxCode();
			setTimeout(() => {
				$(".serch_icon").click();
			}, 300);
		},
		deleteEle(index) {
			let total = 0;
			$.each(this.finalizedItems, (key, detail) => {
				if (key != index) {
					let feeSubmitted = String(detail.fee_submitted).replace(',', '');
					let tempTotal = (detail.number_of_service * feeSubmitted)
					let discountPer = detail.discount
					let discountAmt = 0;
					if (discountPer) {
						discountAmt = ((discountPer * tempTotal) / 100)
						total += discountAmt;
					}
					else {
						total += tempTotal;
					}
				}
				else {
					const { invoiceId, invoiceType } = this.$route.query;
					if (invoiceId && invoiceType) {
						this.onEditRemoveIds.push(detail.invoice_detail_id);
					}
				}
			});
			this.grandTotal = `$${parseFloat(total).toFixed(2)}`;
			this.finalizedItems.splice(index, 1);
		},
		searchBillingCode() {
			this.billingCodeList = []
			this.billingCodeDescList = []
			this.dxCodeList = []
			// if (this.itemForm.service_code.length > 0){
			axios.post("/invoice/search-billing-code", { term: this.itemForm.service_code, type: this.form.type })
				.then((response) => {
					this.billingCodeList = response.data.data;
					this.preBillingCodeList = response.data.data;
				})
			// }
		},
		searchBillingDesc() {
			this.billingCodeDescList = []
			this.billingCodeList = [];
			axios.post("/invoice/search-billing-code", { term: this.itemForm.service_code_description, type: this.form.type })
				.then((response) => {
					this.billingCodeDescList = response.data.data;
				})
		},
		searchDxCode() {
			this.dxCodeList = []
			this.billingCodeList = []
			axios.post("/invoice/dx-code", { term: this.itemForm.dx_code, type: this.form.type })
				.then((response) => {
					this.dxCodeList = response.data.data;
					this.preDxCodeList = response.data.data;
				})

		},
		checkduplicate() {
			if (this.itemForm.service_code) {
				var duplicateFound = this.finalizedItems.some(val => val.service_code.toLowerCase() === this.itemForm.service_code.toLowerCase());
				if (duplicateFound) {
					this.$filters.moshaToast("You cannot add duplicate billing codes.", "error");
					return false;
				} else {
					const matchedRecord = this.preBillingCodeList.find(item => item.code.toLowerCase() === this.itemForm.service_code.toLowerCase());
					if (matchedRecord) {
						this.itemForm.service_code = matchedRecord.code.toUpperCase();
						this.itemForm.service_code_description = matchedRecord.description;
						this.itemForm.fee_submitted_format = matchedRecord.formatted_fee;
						this.itemForm.fee_submitted = matchedRecord.fee;
					}
				}
			}
		},
		selectBillingCode(item) {
			var duplicateFound = this.finalizedItems.some(val => val.service_code.toLowerCase() === item.code.toLowerCase());
			if (duplicateFound) {
				this.$filters.moshaToast("You cannot add duplicate billing codes.", "error");
				return false;
			}
			this.itemForm.service_code = item.code
			this.itemForm.service_code_description = item.description
			this.itemForm.fee_submitted_format = item.formatted_fee
			this.itemForm.fee_submitted = item.fee
			this.billingCodeList = []
			this.calculateGrnadTotal()
			if (this.form.type != "Ontario") {
				this.billingCodeDescList = []
			}
		},
		addDxDescription() {
			if (this.itemForm.dx_code) {
				const matchedRecord = this.preDxCodeList.find(item => item.code.toLowerCase() === this.itemForm.dx_code.toLowerCase());
				if (matchedRecord) {
					this.itemForm.dx_code = matchedRecord.code
					this.itemForm.dx_code_description = matchedRecord.description
				}
			}
		},
		selecDxCode(item) {
			this.itemForm.dx_code = item.code
			this.itemForm.dx_code_description = item.description
			this.dxCodeList = []
		},
		editEle(index, item) {
			if(!this.isShowBillingFrmEle){
				this.$store.state.billing.itemForm = item
				this.finalizedItems.splice(index, 1);
				this.isShowBillingFrmEle = true;
				this.searchBillingCode();
				this.searchDxCode();
				setTimeout(() => {
					$(".serch_icon").click();
				}, 300);
			}
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split(/[-–]/).map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate != '') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if (obj == 'service_date') {
					this.itemForm.service_date = moment(sanitizedInput).format('YYYY-MM-DD');
				} else {
					this.form[obj] = moment(sanitizedInput).format('YYYY-MM-DD');
				}
			} else {
				if (formattedDate != '') {
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		calculateGrnadTotal() {
			let total = 0;
			$.each(this.finalizedItems, function (key, detail) {
				let feeSubmitted = String(detail.fee_submitted).replace(',', '');
				let tempTotal = (detail.number_of_service * feeSubmitted)
				let discountPer = detail.discount
				let discountAmt = 0;
				if (discountPer) {
					discountAmt = ((discountPer * tempTotal) / 100)
					total += discountAmt;
				}
				else {
					total += tempTotal;
				}
			})
			this.grandTotal = `$${parseFloat(total).toFixed(2)}`
		},
		fetchPreviousInvoice() {
			// let filteredArray = (this.form.provider_id) ? [this.form.provider_id] : null;
			axios.post("/invoice/fetch-previous-invoices", { 'type': this.form.type, 'patient_id': this.form.patient_id })
				.then((response) => {
					this.previousInvoices = response.data.data;
				})
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target) && !event.target.closest(`.search_drop_box`)) {
				this.patientList = [];
			}

			const billingCodeRefElement = this.$refs.billingCodeRef;
			if (billingCodeRefElement && !billingCodeRefElement.contains(event.target) && !event.target.closest(`.seach_input_bar`)) {
				if (this.billingCodeList.length > 0) {
					this.billingCodeList = [];
				}
			}


			const dxCodeRefElement = this.$refs.dxCodeRef;
			if (dxCodeRefElement && !dxCodeRefElement.contains(event.target) && !event.target.closest(`.seach_input_bar`)) {
				if (this.dxCodeList.length > 0) {
					this.dxCodeList = [];
				}
			}

			const billingPrivateDescRefElement = this.$refs.billingPrivateDescRef;
			if (billingPrivateDescRefElement && !billingPrivateDescRefElement.contains(event.target) && !event.target.closest(`.seach_input_bar`)) {
				if (this.billingCodeDescList.length > 0) {
					this.billingCodeDescList = [];
				}
			}

			const serviceLocationRefEle = this.$refs.serviceLocationRef;
			if (serviceLocationRefEle && !serviceLocationRefEle.contains(event.target) && !event.target.closest(`.location_indicator_ele`)) {
				if (this.filteredServiceLocations.length > 0) {
					this.filteredServiceLocations = [];
				}
			}

			const visitLocationRefEle = this.$refs.visitLocationRef;
			if (visitLocationRefEle && !visitLocationRefEle.contains(event.target) && !event.target.closest(`.visit_location_ele`)) {
				if (this.filteredVisitLocations.length > 0) {
					this.filteredVisitLocations = [];
				}
			}


			const specialityRefEle = this.$refs.specialityRef;
			if (specialityRefEle && !specialityRefEle.contains(event.target) && !event.target.closest(`.speciality_ele`)) {
				if (this.filteredSpecialityCode.length > 0) {
					this.filteredSpecialityCode = [];
				}
			}

		},
		selectBillingCodeDesc(item) {
			this.itemForm.service_code = item.code
			this.itemForm.service_code_description = item.description
			this.billingCodeDescList = []
		},
		cancelBtn() {
			this.$router.push(`/invoice/list`);
		},
		resetForm() {
			Object.assign(
				this.$store.state.billing.itemForm,
				this.$store.state.billing.defaultItemForm,
			);
			Object.assign(
				this.$store.state.billing.form,
				this.$store.state.billing.defaultFormData,
			);
			this.finalizedItems = []
			this.isShowBillingFrmEle = true
			this.grandTotal = '$0.00'
			this.isAdvanceSettings = false
			this.isServiceFax = true
			this.filteredVisitLocations = []
		},
		openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
		async initializeForm() {
			try {
				const patient_id = this.$route.query.patient_id;
				const patient_name = this.$route.query.patient_name;
				const provider_id = this.$route.query.provider_id;
				const provider_name = this.$route.query.provider_name;
				if (patient_id) {
					await this.selectPatient(patient_id, patient_name);
				}
				if (provider_name) {
					this.tagProviderLabel = provider_name;
				}
				this.selected_provider_detail.provider_id = provider_id;

				this.form.provider_id = provider_id;
				this.form.patient_id = patient_id;

				if (this.$route.query.is_private == 1) {
					this.form.type = 'Private';
					this.invoice_type_text = this.$filters.strToUpperCase('Private');
				}
			} catch (error) {
				console.error('Error in initializeForm method:', error);
			}
		},
		loadInvoiceDetails(invoiceId, invoiceType) {
			this.$store.state.loader = true;
			this.form.id = invoiceId;
			this.form.type = invoiceType;
			this.invoice_type_text = this.$filters.strToUpperCase(invoiceType);
			this.isShowBillingFrmEle = false;
			axios.post('invoice/retrieve', { 'invoice_id': invoiceId, 'type': invoiceType })
				.then((response) => {
					if (response.status == 200) {
						const invoiceDetailsData = response.data.data.invoice_details_data;
						const invoiceDetails = response.data.data.invoice_details;
						const total = `$${response.data.data.total}`;
						this.finalizedItems = invoiceDetails.map(item => {
							return {
								...item,
								fee_submitted_format: `$${item.fee_submitted}`,
								invoice_id: invoiceId,
								invoice_detail_id: item.id
							}
						});
						// this.finalizedItems = invoiceDetails;
						this.grandTotal = total;

						this.selectPatient(invoiceDetailsData.patient_id, invoiceDetailsData.patient_name);
						this.form.patient_id = invoiceDetailsData.patient_id;
						this.form.provider_id = invoiceDetailsData.provider_id;
						/* Provider Selection */
						this.provider_list = this.provider_list.map(item => {
							return {
								id: item.id,
								name: item.name,
								checked: item.id == invoiceDetailsData.provider_id,
								extInfo: item.extInfo
							};
						});
						this.select_box_key = this.select_box_key+1;
						let obj = this.provider_list.filter(item => item.checked)
						if (obj.length > 0) {
							this.tagProviderLabel = obj[0].name;
							this.form['provider_id'] = obj[0].id;
							const selectedProviderDetail = obj[0].extInfo;
							if (invoiceDetailsData.visit_location) {
								this.form.visit_location = invoiceDetailsData.visit_location;
								const code = invoiceDetailsData.visit_location.split(/[-–]/)[0].trim();
								this.form.visit_location_text = code;
							} else {
								this.form.visit_location_text = '';
								this.form.visit_location = null;
							}

							if (invoiceDetailsData.service_location) {
								this.form.service_location = invoiceDetailsData.service_location;
								const code = invoiceDetailsData.service_location.split(/[-–]/)[0].trim();
								this.form.service_location_text = code;
							} else {
								this.form.service_location_text = '';
								this.form.service_location = null;
							}
							this.selected_provider_detail.cpdid = selectedProviderDetail.cpsid ? selectedProviderDetail.cpsid : '';
							this.selected_provider_detail.specialty_code = selectedProviderDetail.specialty_code ? selectedProviderDetail.specialty_code : '';
							this.selected_provider_detail.group_billing_no = selectedProviderDetail.group_billing_no ? selectedProviderDetail.group_billing_no : '';
						}
						
						this.form.health_insurance_no = invoiceDetailsData.health_insurance_no ? invoiceDetailsData.health_insurance_no : '';
						this.form.health_card_ver = invoiceDetailsData.health_card_ver ? invoiceDetailsData.health_card_ver : '';
						
						this.form.province_code_text = invoiceDetailsData.province_code_text ? invoiceDetailsData.province_code_text : '';
						this.form.province_code = invoiceDetailsData.province_code ? invoiceDetailsData.province_code : '';
						this.form.claim_payee = invoiceDetailsData.claim_payee ? invoiceDetailsData.claim_payee : '';

						this.form.rmb_patient_last_name = invoiceDetailsData.last_name ? invoiceDetailsData.last_name : '';
						this.form.rmb_patient_first_name = invoiceDetailsData.first_name ? invoiceDetailsData.first_name : '';
						this.form.dob = invoiceDetailsData.dob ? invoiceDetailsData.dob : '';
						this.form.rmb_patient_sex = invoiceDetailsData.rmb_patient_sex ? invoiceDetailsData.rmb_patient_sex : '';

						if (invoiceDetailsData.rmb_patient_sex) {
							switch (invoiceDetailsData.rmb_patient_sex) {
								case 'M':
									this.form.gender_text = 'Male';
									break;
								case 'F':
									this.form.gender_text = 'Female';
									break;
								case 'O':
									this.form.gender_text = 'Other';
									break;
								case 'T':
									this.form.gender_text = 'Trans';
									break;
								default:
									break;
							}
						}
						if (invoiceDetailsData.claim_type) {
							let claimOption = this.claim_type_option.filter(option => option.value === invoiceDetailsData.claim_type);
							if (claimOption.length > 0) {
								this.form.claim_type_text = claimOption[0].title.split('|')[0].trim();
								this.form.claim_type = claimOption[0].title.split(/[-–]/)[0].trim();
							}
						}
						this.form.referral_hcp_number = invoiceDetailsData.referral_hcp_number ? invoiceDetailsData.referral_hcp_number : '';
						this.form.inpatient_admission_date = invoiceDetailsData.inpatient_admission_date ? invoiceDetailsData.inpatient_admission_date : '';
						this.form.referring_lab_license_number = invoiceDetailsData.referring_lab_license_number ? invoiceDetailsData.referring_lab_license_number : '';
						this.form.group_number = invoiceDetailsData.group_number ? invoiceDetailsData.group_number : '';
						this.form.specialty_code = invoiceDetailsData.specialty_code ? invoiceDetailsData.specialty_code : '';
						this.form.master_number = invoiceDetailsData.master_number ? invoiceDetailsData.master_number : '';
						this.form.manual_review = invoiceDetailsData.manual_review ? invoiceDetailsData.manual_review : false;
						this.form.specialty_code_desc = invoiceDetailsData.specialty_code_desc ? invoiceDetailsData.specialty_code_desc : '';
						this.$store.state.loader = false;

					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		searchVisitLocation(){
			this.resetSeachDropdown()
			if (!this.form.visit_location_text) {
				this.form.visit_location_text = ''
				this.form.visit_location = ''
			}

			this.filteredVisitLocations =  this.visitLocationData.filter(item =>
				item.title.toLowerCase().includes(this.form.visit_location_text.toLowerCase())
			);
		},
		selectVisitlocation(item){
			this.form.visit_location_text = item.value
			this.form.visit_location = item.value
			this.filteredVisitLocations = []
		},
		searchServiceLocation(){
			this.resetSeachDropdown()
			if (this.form.service_location_text == 'None' || !this.form.service_location_text) {
				this.form.service_location_text = ''
				this.form.service_location = ''
			}
			this.filteredServiceLocations =  this.serviceLocationData.filter(item =>
				item.title.toLowerCase().includes(this.form.service_location_text.toLowerCase())
			);
		},
		selectServicelocation(item){
			this.form.service_location_text = item.value
			this.form.service_location = item.value
			this.filteredServiceLocations = []
		},
		resetSeachDropdown(){
			this.filteredServiceLocations = []
			this.filteredVisitLocations = []
			this.filteredSpecialityCode = []
		},
		searchSpecialityCode(){
			this.resetSeachDropdown()
			if (!this.form.specialty_code_desc) {
				this.form.specialty_code_desc = ''
				this.form.specialty_code = ''
				this.form.specialty_description = ''
			}
			this.filteredSpecialityCode =  this.specialityCodes.filter(item =>
				item.title.toLowerCase().includes(this.form.specialty_code_desc.toLowerCase())
			);
		},
		selectSpecialityCode(item){
			this.form.specialty_code_desc = item.title
			this.form.specialty_code = item.value
			this.form.specialty_description = item.desc
			this.filteredSpecialityCode = []
		},
		fetchSpecialityCodes(){
			axios.get("json_autocomplete/speciality-code?is_front_format=1")
				.then((response) => {
					this.specialityCodes = response.data.data;
				})
		},
		fetchVisitLocationMaster(){
			axios.get("json_autocomplete/visit-location-master")
			.then((response) => {
				this.visitLocationData = [
				{ value: "0000", title: "0000 - [] No Location" }, // Add the first row
				...response.data.data.map(item => {
						// eslint-disable-next-line no-unused-vars
						const [value, title] = item.split(/[-–]/);
						return {
							title: item, // Use the title part or the entire item if no title part is found
							value: value || '',  // Default to an empty string if no value part is found
						};
					})
				];
			})
		}
	},
	mounted: async function () {
		document.addEventListener('click', this.handleClickOutside);
		this.resetForm()
		await this.getProviderList();
		var $this = this;
		$(document).on("click", '._datepic', function () {
			const inputField = $(this).find('input');
			const inputId = inputField.attr('id');
			$this.openDatePicker(inputId);
		});
		const { invoiceId, invoiceType } = this.$route.query;
		if (invoiceId && invoiceType) {
			this.isEditInvoice = true;
			this.btnlbl = 'Save & Submit';
			this.loadInvoiceDetails(invoiceId, invoiceType);
		}
		this.fetchSpecialityCodes()
		this.fetchVisitLocationMaster()
		
	},
	computed: {
		form() {
			return this.$store.state.billing.form;
		},
		itemForm() {
			return this.$store.state.billing.itemForm;
		},
		isTypeDropDownDisabled() {
			return !!this.form.id;
		}
	},
	watch: {
		"form.rmb_registration_number"(newVal) {
			if (newVal && newVal != '') {
				this.form.claim_type_text = "RMB - Reciprocal Medical Billing";
				this.form.claim_type = "RMB";
			} else {
				this.form.claim_type_text = "HCP - Health Claims Program";
				this.form.claim_type = "HCP";
			}
		},
		"patient_detail.health_insurance_type"(newVal) {
			const { invoiceId, invoiceType } = this.$route.query;
			if (newVal && (!invoiceId || !invoiceType)) {
				const matchedRecord = this.stateData.find(item => item.value.toLowerCase() === newVal.toLowerCase());
				if (matchedRecord) {
					this.form.province_code_text = matchedRecord.title;
					this.form.province_code = matchedRecord.value;
					if (this.form.province_code == 'ON' || this.form.province_code == 'QC') {
						this.form.claim_type_text = "HCP - Health Claims Program";
						this.form.claim_type = "HCP";
					} else {
						this.form.claim_type_text = "RMB - Reciprocal Medical Billing";
						this.form.claim_type = "RMB";
						this.form.rmb_registration_number = this.patient_detail.health_insurance_no;
					}
				}
			}
		}
	},
	async created() {
		await this.initializeForm();
	},
}
</script>
